module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://tools.focus-home.com/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hood: Outlaws & Legends","short_name":"Beta Hood","start_url":"/","background_color":"#ffffff","theme_color":"#0b161a","display":"minimal-ui","icon":"src/images/hood-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff33821cc5e1da30775bde678923a306"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import PropTypes from "prop-types";
import React from "react";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-100 bg-blue-hood">
      <main>
        {children}
      </main>

      <footer className="w-full max-w-4xl px-4 py-8 mx-auto mt-10 text-center border-t border-gray-800 md:mt-12 md:px-8">
        <ul className="grid items-center justify-center grid-cols-2 gap-8 mb-4 overflow-hidden sm:grid-cols-3 md:flex md:space-x-8">
          <li>
            <a href="https://www.focus-home.com" rel="noopener noreferrer nofollow" target="_blank">
              <img loading="lazy" alt="Focus Home Interactive" src="https://cdn.focus-home.com/fhi-fastforward-admin/resources/partners/focus-studio/images/29062020_90ec15f436ba4dffb33732c92384cd42.png" className="h-12 mx-auto" />
            </a>
          </li>
          <li>
            <a href="https://www.sumo-digital.com/" rel="noopener noreferrer nofollow" target="_blank">
              <img loading="lazy" alt="Sumo Digital" src="https://cdn.focus-home.com/fhi-fastforward-admin/resources/partners/sumo-newcastle/images/26082020_9160862cde3c44f396f667d8cf4df22f.png" className="h-12 mx-auto" />
            </a>
          </li>
        </ul>
        <p className="text-sm">© 2021 Hood: Outlaws & Legends, a game developed by Sumo Digital and published by Focus Home Interactive. Hood: Outlaws & Legends and its logos are Focus Home Interactive’s trademarks. All rights reserved.</p>
        <nav className="flex flex-wrap justify-center mt-8 "><div className="px-5 py-2"><a href="https://focus-home.com/privacy-policy/" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 text-white hover:underline">Privacy &amp; Cookie Policy</a></div><div className="px-5 py-2"><a href="https://focus-home.com/eula/" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 text-white hover:underline">End user licence agreement</a></div><div className="px-5 py-2"><a href="https://focus-home.com/legal" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 text-white hover:underline">Legal Information</a></div><div className="px-5 py-2"><a href="mailto:support@focus-home.com" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 text-white hover:underline">Support</a></div></nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

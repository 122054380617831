import { useEffect, useState } from "react";
import { useMutation, gql } from '@apollo/client';
import axios from "axios";
import jwt from "jsonwebtoken";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "./../images/hood-banner.jpeg";
import logo from "./../images/hood-logo.png";

const useStateWithLocalStorage = (localStorageKey, initValue) => {
  let [value, setValue] = useState(initValue);
  if (typeof window !== 'undefined') {
    [value, setValue] = useState(
      JSON.parse(localStorage.getItem(localStorageKey)) || initValue
    );
  }
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value]);
  return [value, setValue];
};

function IndexPage() {

  const [twitchUser, setTwitchUser] = useStateWithLocalStorage('twitchUser', {});
  const [steamUser, setSteamUser] = useStateWithLocalStorage('steamUser', {});
  const [createSubscriber, { loading }] = useMutation(CREATE_SUBSCRIBER);
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const deleteSteamAccount = () => {
    setSteamUser({});
    localStorage.removeItem('steamUser');
  }

  const deleteTwitchAccount = () => {
    setTwitchUser({});
    localStorage.removeItem('twitchUser');
  }

  useEffect(async () => {
    try {
      if (twitchUser.accessToken) {
        await axios.get('https://id.twitch.tv/oauth2/validate', {
          headers: {
            'Authorization': `OAuth ${jwt.verify(twitchUser.accessToken, process.env.GATSBY_API_SECRET)}`
          }
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        deleteTwitchAccount()
      }
    }

    window.addEventListener("message", async (event) => {
      if (event.origin !== process.env.GATSBY_API_URL) return;

      const { token, type, ok } = event.data;
      if (ok) {
        const { user } = jwt.verify(token, process.env.GATSBY_API_SECRET);
        if (type === 'twitch') {
          setTwitchUser(user);
        } else if (type === 'steam') {
          setSteamUser(user);
        }
      }
    });
  }, []);

  const handleClick = async (type) => {
    const popupWindow = window.open(
      `${process.env.GATSBY_API_URL}/auth/${type}`,
      "_blank",
      "width=800, height=600",
    );
    if (window.focus) popupWindow.focus();
  }

  const handleSubmit = async () => {
    try {
      await createSubscriber({
        variables: {
          twitch_id: twitchUser.id,
          steam_id: steamUser.id,
        }
      });
      setSuccess(true)
    } catch (er) {
      if (er.message.startsWith('Twitch account') || er.message.startsWith('Steam account')) {
        setSuccess(true)
      } else {
        setError("There is an error");
      }
    }
  }

  return (
    <Layout>
      <SEO
        title="Twitch Drops"
      />
      <section className="pt-8 mb-10">
        <img className="mx-auto md:max-w-3xl" alt="Hood: Outlaws & Legends" src={logo} />
        <div className="relative mt-4">
          <div className="absolute top-0 left-0 z-10 w-full h-24 bg-gradient-to-b from-blue-hood"></div>
          <div className="absolute bottom-0 left-0 z-10 w-full h-24 bg-gradient-to-t from-blue-hood"></div>
          <img className="object-cover object-center w-full h-64 md:h-auto" alt="" src={Banner} />
        </div>
        <h1 className="relative z-20 px-4 -mt-12 text-2xl text-center uppercase sm:text-3xl font-display sm:px-0">Hood: Outlaws & Legends Twitch Drops</h1>
      </section>
      <section className="w-full max-w-2xl px-4 mx-auto">
        <div className="divide-y divide-gray-800">
          <div className="pb-6 space-y-4">
            <div>
              <h1 className="text-2xl font-bold text-white">Step 1</h1>
              <p className="mt-1 font-medium text-gray-200">Login to the Twitch account you used to claim the Hood: Outlaws & Legends Drop</p>
            </div>
            {!twitchUser.id && <button type="button" className="inline-flex items-center justify-between px-6 py-3 mt-1 text-base font-medium leading-6 text-white bg-twitch-normal hover:bg-twitch-hover active:bg-twitch-active" onClick={() => handleClick('twitch')}>
              <span>Login with Twitch</span>
              <span>
                <svg className="h-6 ml-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 19.5" fill="currentColor" focusable="false">
                  <path d="M4.7,19.2c0-0.8,0-1.6,0-2.4c-1.5,0-3,0-4.5,0c0-0.3-0.1-0.4-0.1-0.6C0.1,12.1,0,8,0.2,3.9c0-1.2,0.7-2.5,1.2-3.7 c0-0.1,0.3-0.2,0.4-0.2c5.5,0,11,0,16.5,0c0,0,0.1,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,3.5,0,6.9,0,10.4c0,0.3-0.2,0.7-0.4,0.9 c-1.4,1.4-2.8,2.9-4.2,4.3c-0.2,0.2-0.5,0.3-0.8,0.3c-0.9,0-1.9,0-2.8,0c-0.3,0-0.6,0.2-0.8,0.3c-0.3,0.2-0.6,0.5-0.9,0.9 C7.6,19.4,6.3,19.5,4.7,19.2z M16.8,1.8c-4.7,0-9.2,0-13.8,0c0,4,0,8.1,0,12.1c1.3,0,2.5,0,3.8,0c0,0.8,0,1.6,0,2.5 c0.8-0.8,1.4-1.5,2.1-2.2c0.2-0.2,0.6-0.4,0.9-0.4c1.2,0,2.4,0,3.6,0c0.3,0,0.6-0.1,0.8-0.3c0.8-0.7,1.5-1.5,2.2-2.2 c0.2-0.2,0.4-0.6,0.4-0.9c0-1.9,0-3.8,0-5.7C16.8,3.7,16.8,2.8,16.8,1.8z"></path><path d="M7.7,5.1c0.5,0,1,0,1.5,0c0,1.6,0,3.3,0,4.9c-0.5,0-1,0-1.5,0C7.7,8.4,7.7,6.8,7.7,5.1z"></path><path d="M12.3,10.1c0-1.7,0-3.3,0-4.9c0.5,0,1,0,1.6,0c0,1.6,0,3.2,0,4.9C13.3,10.1,12.8,10.1,12.3,10.1z"></path>
                </svg>
              </span>
            </button>}
            {twitchUser.id &&
              <div className="flex items-center space-x-3">
                <svg className="h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 19.5" fill="currentColor" focusable="false">
                  <path d="M4.7,19.2c0-0.8,0-1.6,0-2.4c-1.5,0-3,0-4.5,0c0-0.3-0.1-0.4-0.1-0.6C0.1,12.1,0,8,0.2,3.9c0-1.2,0.7-2.5,1.2-3.7 c0-0.1,0.3-0.2,0.4-0.2c5.5,0,11,0,16.5,0c0,0,0.1,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,3.5,0,6.9,0,10.4c0,0.3-0.2,0.7-0.4,0.9 c-1.4,1.4-2.8,2.9-4.2,4.3c-0.2,0.2-0.5,0.3-0.8,0.3c-0.9,0-1.9,0-2.8,0c-0.3,0-0.6,0.2-0.8,0.3c-0.3,0.2-0.6,0.5-0.9,0.9 C7.6,19.4,6.3,19.5,4.7,19.2z M16.8,1.8c-4.7,0-9.2,0-13.8,0c0,4,0,8.1,0,12.1c1.3,0,2.5,0,3.8,0c0,0.8,0,1.6,0,2.5 c0.8-0.8,1.4-1.5,2.1-2.2c0.2-0.2,0.6-0.4,0.9-0.4c1.2,0,2.4,0,3.6,0c0.3,0,0.6-0.1,0.8-0.3c0.8-0.7,1.5-1.5,2.2-2.2 c0.2-0.2,0.4-0.6,0.4-0.9c0-1.9,0-3.8,0-5.7C16.8,3.7,16.8,2.8,16.8,1.8z"></path><path d="M7.7,5.1c0.5,0,1,0,1.5,0c0,1.6,0,3.3,0,4.9c-0.5,0-1,0-1.5,0C7.7,8.4,7.7,6.8,7.7,5.1z"></path><path d="M12.3,10.1c0-1.7,0-3.3,0-4.9c0.5,0,1,0,1.6,0c0,1.6,0,3.2,0,4.9C13.3,10.1,12.8,10.1,12.3,10.1z"></path>
                </svg>
                <span className="text-lg">{twitchUser.displayName}</span>
                <button type="button" onClick={() => deleteTwitchAccount()} className="inline-flex items-center justify-between px-3 py-2 mt-1 text-base font-medium leading-6 text-gray-900 bg-white hover:bg-gray-200 active:bg-gray-300">
                  <span>Log out</span>
                </button>
              </div>
            }
          </div>
          <div className="py-6 space-y-4">
            <div>
              <h1 className="text-2xl font-bold text-white">Step 2</h1>
              <p className="mt-1 font-medium text-gray-200">
                {twitchUser.id ? 'Login to the Steam account that will have access to the Twitch Drop' : 'Associated account'}
              </p>
            </div>
            {twitchUser.id && !steamUser.id && <button type="button" className="inline-flex items-center justify-between px-6 py-3 mt-1 text-base font-medium leading-6 text-white bg-gray-700 hover:bg-gray-600 active:bg-gray-900" onClick={() => handleClick('steam')}>
              <span>Login with Steam</span>
              <span>
                <svg className="h-6 ml-3 text-white" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.5 56.6" xmlSpace="preserve"> <path d="M28.2,0.2C13.5,0.2,1.5,11.4,0.3,25.8c0.6,0.2,13.5,5.3,13.5,5.3 c1.3-1.2,3.1-1.9,4.9-1.9c0.3,0,0.6,0,0.9,0.1l6.6-9.1c0-5.5,4.5-10,10-10 c5.5,0,10,4.5,10,10s-4.5,10-10,10c-0.5,0-1.1-0.1-1.6-0.1l-8.4,6.3c0,0.1,0,0.2,0,0.3 c0,4.1-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5c0-0.1,0-0.1,0-0.2L0.5,32.2c0,0,0,0,0,0c0,0,0,0,0,0 c1.9,13.6,13.6,24,27.7,24c15.5,0,28-12.5,28-28S43.7,0.2,28.2,0.2z"></path> <path d="M17.1,38.9l-2.8-1.1c0.5,2,2.2,3.4,4.4,3.4c2.5,0,4.5-2,4.5-4.5 s-2-4.5-4.5-4.5c-0.5,0-1,0.1-1.5,0.3l2.1,0.8c1.6,0.6,2.3,2.3,1.7,3.9 C20.4,38.8,18.6,39.5,17.1,38.9z"></path> <path d="M36.2,13.2c-3.9,0-7,3.1-7,7c0,2.8,1.6,5.2,4,6.3l1.4,0.5 c0.5,0.1,1.1,0.2,1.6,0.2c3.9,0,7-3.1,7-7S40.1,13.2,36.2,13.2z M36.2,25.2c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S38.9,25.2,36.2,25.2z"></path></svg>
              </span>
            </button>}
            {twitchUser.id && steamUser.id &&
              <div className="flex items-center space-x-3">
                <svg className="h-6" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.5 56.6" xmlSpace="preserve"> <path d="M28.2,0.2C13.5,0.2,1.5,11.4,0.3,25.8c0.6,0.2,13.5,5.3,13.5,5.3 c1.3-1.2,3.1-1.9,4.9-1.9c0.3,0,0.6,0,0.9,0.1l6.6-9.1c0-5.5,4.5-10,10-10 c5.5,0,10,4.5,10,10s-4.5,10-10,10c-0.5,0-1.1-0.1-1.6-0.1l-8.4,6.3c0,0.1,0,0.2,0,0.3 c0,4.1-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5c0-0.1,0-0.1,0-0.2L0.5,32.2c0,0,0,0,0,0c0,0,0,0,0,0 c1.9,13.6,13.6,24,27.7,24c15.5,0,28-12.5,28-28S43.7,0.2,28.2,0.2z"></path> <path d="M17.1,38.9l-2.8-1.1c0.5,2,2.2,3.4,4.4,3.4c2.5,0,4.5-2,4.5-4.5 s-2-4.5-4.5-4.5c-0.5,0-1,0.1-1.5,0.3l2.1,0.8c1.6,0.6,2.3,2.3,1.7,3.9 C20.4,38.8,18.6,39.5,17.1,38.9z"></path> <path d="M36.2,13.2c-3.9,0-7,3.1-7,7c0,2.8,1.6,5.2,4,6.3l1.4,0.5 c0.5,0.1,1.1,0.2,1.6,0.2c3.9,0,7-3.1,7-7S40.1,13.2,36.2,13.2z M36.2,25.2c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S38.9,25.2,36.2,25.2z"></path></svg>
                <span className="text-lg">{steamUser.displayName}</span>
                <button type="button" onClick={() => deleteSteamAccount()} className="inline-flex items-center justify-between px-3 py-2 mt-1 text-base font-medium leading-6 text-gray-900 bg-white hover:bg-gray-200 active:bg-gray-300">
                  <span>Log out</span>
                </button>
              </div>
            }
          </div>
          <div className="pt-6 space-y-4">
            <div>
              <h1 className="text-2xl font-bold text-white">Step 3</h1>
              <p className="mt-1 font-medium text-gray-200">
                {twitchUser.id && steamUser.id ? 'Confirm to get the Twitch Drop on your Steam account once the Twitch Drops campaign is finished' : 'Confirmation'}
              </p>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">Success! We'll automatically add the rewards in-game soon.</p>}
            {twitchUser.id && steamUser.id && !success &&
              <button type="button" onClick={() => handleSubmit()} className="inline-flex items-center justify-center px-6 py-3 mx-auto mt-1 text-base font-medium leading-6 text-gray-900 bg-white hover:bg-gray-200 active:bg-gray-300">
                {loading ? (
                  <>
                    <svg className="w-5 h-5 mr-3 -ml-5 text-gray-900 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Loading</span>
                  </>) : (
                  <span>Confirm</span>
                )}
              </button>
            }
          </div>
        </div>
      </section>
      <section className="max-w-4xl px-4 mx-auto mt-12 md:px-8">
        <p className="mb-6 text-sm text-left">
          <span className="font-semibold">You must complete the two preliminary steps to be eligible for your Hood Outlaws & Legends Twitch Drops reward</span> Focus Home Interactive acting as data controller will collect and reconcile your Twitch and Steam credentials to enable you to obtain the promised reward. Your data will not be communicated to any recipient. The legal basis for the processing is our legitimate interest in promoting our games and developing our community while rewarding the players. Your identifying data will be kept only for the duration of the Hood Outlaws & Legends Twitch Drops operation and then we will delete it from our servers. You may access, correct, request the deletion of your data, exercise your right to limit the processing of your data or object to the processing of your data at any time by sending a written request using this <a className="border-b border-white border-dashed hover:border-solid" href="https://www.focus-home.com/privacy-form">form</a> or by contacting our data protection officer at the following address: personaldata@focus-home.com. If, after having contacted us, you believe that your `Data Privacy` rights have not been respected, you can send a complaint to the competent supervisory authority, such as the French Data Protection Authority (Commission Nationale de l’Informatique et des Libertés). To learn more about the processing of your personal data, please consult our <a className="border-b border-white border-dashed hover:border-solid" href="https://www.focus-home.com/privacy-policy">privacy policy</a>.
        </p>
      </section>
    </Layout>
  );
}

const CREATE_SUBSCRIBER = gql`
  mutation createDropSubscriberMutation($twitch_id: String!, $steam_id: String!) {
    createHoodDropSubscriber(twitch_id: $twitch_id, steam_id: $steam_id) {
      twitch_id
    }
  }
`;

export default IndexPage;
